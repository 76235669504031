import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  collection,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { ref, getStorage, uploadBytes, UploadMetadata } from 'firebase/storage';

export class Firebase {
  constructor({ apiKey, messagingSenderId, appId, measurementId }) {
    this.apiKey = apiKey;
    this.messagingSenderId = messagingSenderId;
    this.appId = appId;
    this.measurementId = measurementId;
  }

  /**
   * Initialize the Firebase database instance.
   * This method should only be called once at the start of the web.
   * @returns The current Firebase instance
   */
  async init() {
    const firebaseConfig = {
      apiKey: this.apiKey,
      authDomain: 'neureader-cait.firebaseapp.com',
      projectId: 'neureader-cait',
      storageBucket: 'neureader-cait.appspot.com',
      messagingSenderId: this.messagingSenderId,
      appId: this.appId,
      measurementId: this.measurementId,
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    this.app = app;
    this.analytics = analytics;

    this.firestore = getFirestore(this.app);
    this.storage = getStorage(this.app);

    return this;
  }

  /**
   * Return ids and all data of documents in a collection
   * @param {String} collectionName The collection name
   * @returns All documents data of that collection
   */
  async documents(collectionName) {
    const querySnapshot = await getDocs(collection(this.firestore, collectionName));
    const docs = querySnapshot.docs.map(
      (doc) => new Document({ id: doc.id, data: doc.data() })
    );
    return docs;
  }

  /**
   * Return the document instance of the document
   * whose id is {documentId} in {collection}
   * @param {String} collectionName The collection name
   * @param {String} documentId The document's id
   * @returns The document instance
   */
  async document(collectionName, documentId) {
    const querySnapshot = await getDoc(
      collection(this.firestore, collectionName, documentId)
    );
    return new Document({ id: querySnapshot.id, data: querySnapshot.data() });
  }

  /**
   * Set the data for a document.
   * If the document has not existed yet, it will be created.
   * If the document has existed, it will be overriden
   * @param {String} collectionName The name of the collection
   * @param {String} documentId The id of the document
   * @param {Map} data The data in key-value
   */
  async set(collectionName, documentId, data) {
    await setDoc(collection(this.firestore, collectionName, documentId), data);
  }

  /**
   * Update data for a document.
   * If the document has not existed, it will throw an error.
   * If the document has existed, only fields in {data} params will be overriden
   * @param {String} collectionName The name of the collection
   * @param {String} documentId The id of the document
   * @param {Map} data The data in key-value
   */
  async update(collectionName, documentId, data) {
    await updateDoc(collection(this.firestore, collectionName, documentId), data);
  }

  /**
   * Delete an existed document.
   * @param {String} collectionName The collection name
   * @param {String} documentId The id of the document
   */
  async delete(collectionName, documentId) {
    await deleteDoc(collection(this.firestore, collectionName, documentId));
  }

  /**
   * Upload a file into the given path of storage database.
   * @param {String} path The target directory path
   * @param {*} fileBytes The file in uint8 bytes
   * @param {UploadMetadata} metadata The metadata of the file. NULLABLE.
   */
  async uploadFile(path, fileBytes, metadata) {
    const directory = ref(this.storage, path);
    await uploadBytes(directory, fileBytes, metadata);
  }
}

class Document {
  constructor({ id, data }) {
    this.id = id;
    this.data = data;
  }
}
