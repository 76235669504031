import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import SaveAndCancel from '../../../../../modules/common/components/SaveAndCancel';
import TableInfoBook from './TableInfoBook';
import TableEditBook from './TableEditBook';
import ButtonUploadFile from './ButtonUploadFile';
import ModalUploadBookAndUser from './ModalUploadBookAndUser';

function AddBook(props) {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenModal = () => {
        setIsOpenModal(!isOpenModal);
    };
    return (
        <div>
            <div className="flex justify-between items-center mb-[2vh]">
                <h1 className="text-[2vw] font-bold">Nhập sách</h1>
                <SaveAndCancel />
            </div>
            <ModalUploadBookAndUser
                name={'sách'}
                nameFile={'Excel'}
                isOpenModal={isOpenModal}
                handleOpenModal={handleOpenModal}
            />
            <div className="flex justify-between items-center w-[50vw] flex-start mb-[4vh]">
                <ButtonUploadFile name="sách" fileName="Excel" handleOpenModal={handleOpenModal} />
                <span className="font-medium mr-auto ml-4">{props.fileName}</span>
            </div>

            <div className="flex justify-between rounded-md">
                <TableInfoBook />
                <TableEditBook />
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        fileName: state.getFileUploadNameReducer.name,
    };
};
export default connect(mapStateToProps)(AddBook);
