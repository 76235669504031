import React from "react";

function ManagerBook(props) {
  return (
    <div>
      <h1>Quản lý gói sách</h1>
    </div>
  );
}

export default ManagerBook;
