import React from "react";
import "../../../../../layouts/dashboard/sidebar/TheSidebar.css";
import "../../../../../index.css";

function ConfirmUploadFile(props) {
  return (
    <div
      className={`w-screen h-screen popup_signout fixed ${props.isOpenConfirmModal ? "activeSignOut" : "hiddenSignOut"} `}
    >
      <div className="w-[20vw] h-[20vh] bg-white rounded-lg ml-auto mr-[3vw] mt-[15vh]">
        <div className="p-[2vh]">
          <h3 className="text-center text-[1.2vw] font-bold">
            Xác nhận tải lên 3 quyển sách?
          </h3>
          <div className="flex justify-center items-center mt-[5vh]">
            <button
              className="text-[1.2vw] font-bold text-red-600 py-3 px-[2vw]"
              onClick={props.handleOpenModalConfirm}
            >
              Huỷ
            </button>
            <button className="text-[1.2vw] font-bold text-white background-303030 rounded-md py-3 px-[2vw]">
              Xác nhận
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmUploadFile;
