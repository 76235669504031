import { add_excel_file } from "../constants/add_book_constant";

const initialState = {
  data: [
    {
      ordinalNumber: "STT",
      bookTitle: "Nhan đề",
      author: "Tác giả",
      manufactureYear: "Năm XB",
      publisher: "Nhà XB",
      term: "Học phần",
      major: "Chuyên ngành",
      types: "Loại",
      category: "Danh mục",
      isbnId: "Mã ISBN",
      department: "Khoa/Viện",
      description: "Giới thiệu",
      bookImporter: "Người nhập",
      price: "Giá",
    },
  ],
};

export default function add_book_reducer(state = initialState, action) {
  switch (action.type) {
    case add_excel_file:
      const dataFetchFromFile = action.file.map((file, index) => {
        return { ordinalNumber: index + 1, ...file };
      });
      const newData = [...state.data, ...dataFetchFromFile];
      // const newData = [...state.data, ...dataFetchFromFile];
      // console.log(newData);
      return { ...state };
    default:
      return { ...state };
  }
}
