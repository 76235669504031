import React, { useState } from 'react';

import './uploadbook.css';

function TableInfoBook(props) {
    const [data, setData] = useState([
        {
            ordinalNumber: 'STT',
            bookTitle: 'Nhan đề',
            author: 'Tác giả',
            manufactureYear: 'Năm XB',
            publisher: 'Nhà XB',
            term: 'Học phần',
            major: 'Chuyên ngành',
            types: 'Loại',
            category: 'Danh mục',
            isbnId: 'Mã ISBN',
            department: 'Khoa/Viện',
            description: 'Giới thiệu',
            bookImporter: 'Người nhập',
            price: 'Giá',
        },
    ]);
    return (
        <div className="overflow-x-auto w-8/12 h-10/12">
            <table className="border-collapse border border-gray-400 rounded-2xl">
                <thead className="background-f2f2f2 ">
                    <tr className="th">
                        <th className="border border-gray-200 pr-3 pl-2 py-[3vh] text-start text-l font-bold th">
                            STT
                        </th>
                        <th className="border border-gray-200 pr-28 whitespace-nowrap text-start tex-l font-bold  pl-2">
                            Nhan đề
                        </th>
                        <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
                            Tác giả
                        </th>
                        <th className="border border-gray-200 pr-4 pl-2 text-start tex-l font-bold">
                            Năm XB
                        </th>
                        <th className="border border-gray-200 pr-12 whitespace-nowrap text-start tex-l font-bold pl-2">
                            Học phần
                        </th>
                        <th className="border border-gray-200 pr-10 whitespace-nowrap tex-l font-bold pl-2">
                            Chuyên ngành
                        </th>
                        <th className="border border-gray-200 tex-l whitespace-nowrap pr-8 font-bold pl-2">
                            Loại
                        </th>
                        <th className="border border-gray-200 pr-12 whitespace-nowrap text-start tex-l font-bold pl-2">
                            Khoa Viện
                        </th>
                        <th className="border border-gray-200 pr-12 whitespace-nowrap text-start tex-l font-bold pl-2">
                            Gói sách
                        </th>
                        <th className="border border-gray-200 tex-l whitespace-nowrap pr-10 font-bold pl-2">
                            Nhà XB
                        </th>
                        <th className="border border-gray-200 tex-l whitespace-nowrap pr-4 font-bold pl-2">
                            Mã ISBN
                        </th>
                        <th className="border border-gray-200 tex-l whitespace-nowrap pr-16 font-bold pl-2">
                            Tóm tắt
                        </th>
                        <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
                            Số trang
                        </th>
                        <th className="border border-gray-200 tex-l whitespace-nowrap pr-16 font-bold pl-2">
                            Giá
                        </th>
                    </tr>
                </thead>
                <tbody className="">
                    <tr>
                        <td className="td border border-gray-200 text-center">1</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">2</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">3</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">4</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">5</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">6</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">7</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">8</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">9</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center">10</td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                        <td className=" border border-gray-200 text-center"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TableInfoBook;
