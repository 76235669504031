import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './TheSidebar.css';
import { CaretDownFilled, CaretUpFilled } from '../../../assets/icons';

function DropdownItem(props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = (para) => {
        setIsOpen(!isOpen);
    };

    return (
        <section>
            <div
                className="flex justify-between items-center py-4 cursor-pointer w-full"
                onClick={handleToggle}
            >
                <span className="basic-1/6 ml-4">
                    {isOpen ? (
                        <props.dataSidebar.darkIcon className="w-[24px] h-[24px] " />
                    ) : (
                        <props.dataSidebar.lightIcon className="w-[24px] h-[24px] " />
                    )}
                </span>
                <div className="flex justify-between items-center basis-5/6 mr-6 ml-3">
                    <p className="basis-4/6 text-lg font-semibold">{props.dataSidebar.title}</p>
                    <span className="w-[22px] h-[22px] mb-2">
                        {isOpen ? (
                            <CaretUpFilled className="w-[22px] h-[22px" />
                        ) : (
                            <CaretDownFilled className="w-[22px] h-[22px" />
                        )}
                    </span>
                </div>
            </div>
            <div className={`dropDownMenu ${isOpen ? 'active' : 'hidden'} `}>
                {props.dataSidebar.dropChildren.map((item, index) => {
                    return (
                        <div className="flex justify-end items-center cursor-pointer w-full ml-2">
                            <NavLink
                                style={{ color: '#909090' }}
                                to={item.path}
                                className={({ isActive }) =>
                                    isActive
                                        ? 'onclicklist flex justify-between items-center w-10/12 mr-6 pl-4 item-hover py-3'
                                        : ' flex justify-between items-center w-10/12 mr-6 pl-4 item-hover py-3'
                                }
                            >
                                <p className=" text-lg w-full font-semibold">{item.name}</p>
                            </NavLink>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}

export default DropdownItem;
