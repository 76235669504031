import React from "react";

function TableInfoAccount(props) {
  return (
    <div className="overflow-x-auto w-11/12 h-10/12">
      <table className="border-collapse border border-gray-400 rounded-2xl">
        <thead className="background-f2f2f2 ">
          <tr className="th">
            <th className="border border-gray-200 pr-3 pl-2 py-[3vh] text-start text-l font-bold th">
              STT
            </th>
            <th className="border border-gray-200 pr-28 whitespace-nowrap text-start tex-l font-bold  pl-2">
              Mã tài khoản
            </th>
            <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
              Tên tài khoản
            </th>
            <th className="border border-gray-200 pr-32 whitespace-nowrap pl-2 text-start tex-l font-bold">
              Ngày tạo
            </th>
            <th className="border border-gray-200 pr-4 pl-2 text-start tex-l font-bold">
              Khoá
            </th>
            <th className="border border-gray-200 tex-l whitespace-nowrap pr-10 font-bold pl-2">
              Nhóm
            </th>

            <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
              Email trường
            </th>
            <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
              Email cá nhân
            </th>
            <th className="border border-gray-200 pr-16 whitespace-nowrap text-start tex-l font-bold pl-2">
              Số điện thoại
            </th>
            <th className="border border-gray-200 tex-l whitespace-nowrap pr-32 font-bold pl-2">
              Lớp
            </th>
            <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
              Khoa viện
            </th>
            <th className="border border-gray-200 pr-32 whitespace-noệnwrap text-start tex-l font-bold pl-2">
              Trường
            </th>
            <th className="border border-gray-200 pr-12 whitespace-nowrap text-start tex-l font-bold pl-2">
              Trạng thái
            </th>
            <th className="border border-gray-200 pr-4 whitespace-nowrap pl-2 text-start tex-l font-bold">
              Số sách đã tải
            </th>
            <th className="border border-gray-200 pr-24 whitespace-nowrap text-start tex-l font-bold pl-2">
              Số thiết bị
            </th>
          </tr>
        </thead>
        <tbody className="">
          <tr>
            <td className="td border border-gray-200 text-center">1</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">2</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">3</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">4</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">5</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">6</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">7</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">8</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">9</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">10</td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
            <td className=" border border-gray-200 text-center"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TableInfoAccount;
