import {
  BookFilled,
  BookStroke,
  HardDrivesFilled,
  HardDrivesStroke,
  UsersFilled,
  UsersStroke,
} from "../../../assets/icons";

export const DataSideBar = [
  {
    title: "Quản lý sách",
    lightIcon: BookStroke,
    darkIcon: BookFilled,
    dropChildren: [
      { path: "/info-Book", name: "Thông tin sách" },
      { path: "/add-Book", name: "Nhập sách" },
      { path: "/manager-Book", name: "Quản lý gói sách" },
    ],
  },
  {
    title: "Quản lý tài khoản",
    lightIcon: UsersStroke,
    darkIcon: UsersFilled,
    dropChildren: [
      { path: "/info-Account", name: "Thông tin tài khoản" },
      { path: "/add-Account", name: "Nhập tài khoản" },
    ],
  },
  {
    title: "Quản lý hệ thống",
    lightIcon: HardDrivesStroke,
    darkIcon: HardDrivesFilled,
    dropChildren: [
      { path: "/decentralize-administration", name: "Phân quyền quản trị" },
      { path: "/statistical-report", name: "Báo cáo thống kê" },
      { path: "/errors-report", name: "Quản lý gói sách" },
    ],
  },
];
