import { Route, Routes } from "react-router-dom";
import routing_name from "./routing_name";
import IndexLayout from "../layouts/index";
import Login from "./pages/Login/Login";

const MainRoutes = () => {
  return (
    <Routes>
      {routing_name.map((route, index) => {
        const Layout = route.layout === "LoginPage" ? Login : IndexLayout;
        const Page = route.component;
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <Layout>
                <Page />
              </Layout>
            }
          />
        );
      })}
    </Routes>
  );
};

export default MainRoutes;
