import React from "react";
import plus from "../../../../../assets/icons/filled/Plus.svg";
import { PlusFilled } from "../../../../../assets/icons";

function TableEditAccount(props) {
  return (
    <div className="w-1/12 ml-2">
      <table className="border-collapse border border-gray-400 rounded-2xl w-full ">
        <thead className="background-f2f2f2 ">
          <tr>
            <th className="border border-gray-200 pr-3 pl-2 text-start text-l font-bold whitespace-nowrap py-[3vh]">
              Chỉnh sửa
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
          <tr>
            <td className="td border border-gray-200 text-center">
              <PlusFilled className="w-[22px] h-[22px] mx-auto" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TableEditAccount;
