import React from "react";
import { UploadSimpleFilled } from "../../../../../assets/icons";

function ButtonUploadFile(props) {
  return (
    <div className="inline">
      <button
        className="flex justify-center items-center background-303030 text-white py-2 px-4 rounded-md"
        onClick={() => {
          props.handleOpenModal();
        }}
      >
        <UploadSimpleFilled className="h-6 w-6 inline mr-2" />
        <span>
          Tải {props.name} từ file {props.fileName}
        </span>
      </button>
    </div>
  );
}

export default ButtonUploadFile;
