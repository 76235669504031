import HomePage from "./pages/Home/HomePage";
import Login from "./pages/Login/Login";
import UploadBookPage from "./pages/Book/UploadBook";
import InfoBookPage from "./pages/Book/InfoBook";
import ManagerBookPage from "./pages/Book/ManagerBook";
import UploadAccountPage from "./pages/Account/UploadAccount";
import DecentralizeAdministrationPage from "./pages/System/DecentralizeAdministation";
import ManagerBookSystemPage from "./pages/System/MangerBookSystem";
import StatisticalReportPage from "./pages/System/StatisticalReport";
import InfoAccountPage from "./pages/Account/InfoAccount";

const routingName = [
  { path: "/", component: HomePage },
  { path: "/add-book", component: UploadBookPage },
  { path: "/info-book", component: InfoBookPage },
  { path: "/manager-book", component: ManagerBookPage },
  { path: "/add-account", component: UploadAccountPage },
  { path: "/info-account", component: InfoAccountPage },
  {
    path: "/decentralize-administration",
    component: DecentralizeAdministrationPage,
  },
  { path: "/manager-book-system", component: ManagerBookSystemPage },
  { path: "/statistical-report", component: StatisticalReportPage },
  { path: "/Login", component: Login, layout: "LoginPage" },
];

export default routingName;
