import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import './TheSidebar.css';
import {
    CaretDownFilled,
    CaretUpFilled,
    SignOutFilled,
    WarningCircleStroke,
} from '../../../assets/icons';

// Khi login sẽ có dữ liệu chuyền vào đây để lấy tên vào Avatả, tuy nhiên chưa merge code nên ở đây mới chỉ là giao diện

function SignOut(props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = (para) => {
        setIsOpen(!isOpen);
    };
    const [isSignout, setIsSignout] = useState(false);
    const handleSignout = () => {
        setIsSignout(!isSignout);
    };
    const handleOverlayClick = () => {
        handleToggle();
        if (isSignout === true) {
            handleSignout();
        }
    };
    return (
        <section>
            <div className="fixed w-[20vw] bottom-[4rem] left-[2rem]">
                <div
                    className={`overlay ${isOpen ? 'active' : ''}`}
                    onClick={handleOverlayClick}
                ></div>
                <div
                    className={`dropDownMenu relative z-50 bg-white rounded-[5px] ${
                        isOpen ? 'active' : 'hidden'
                    } `}
                >
                    <div className="flex justify-between items-center py-4 cursor-pointer w-full border-b-[1px] border-gray-200">
                        <WarningCircleStroke className="w-[24px] h-[24px] basic-1/6 ml-6" />
                        <div className="flex justify-between items-center basis-5/6 ml-4">
                            <p className="basis-4/6 text-lg font-medium">Báo lỗi</p>
                            <div className="basis-2/6"></div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center py-4 cursor-pointer w-full">
                        <SignOutFilled className="w-[24px] h-[24px] basic-1/6 ml-6" />
                        <div
                            className="flex justify-between items-center basis-5/6 ml-4 relative"
                            onClick={handleSignout}
                        >
                            <p className="basis-4/6 text-lg font-medium">Đăng xuất</p>
                            <div className="basis-2/6"></div>
                        </div>
                    </div>
                    <div
                        className={`bg-white border-[1px] w-3/4 rounded-md popup_signout ${
                            isSignout ? 'activeSignOut' : 'hiddenSignOut '
                        } `}
                    >
                        <h1 className="text-lg font-bold my-8 mx-4">Xác nhận đăng xuất?</h1>
                        <div className="flex justify-between w-full gap-2 items-center my-4 px-4">
                            <button
                                className="text-red-600 flex-1 text-l font-bold px-1 py-2 rounded-md hover:border-[1px] hover:border-gray-950"
                                onClick={handleSignout}
                            >
                                Huỷ
                            </button>
                            <button className="flex-1 px-4 py-2 bg-gray-950 text-white text-l font-bold rounded-md hover:bg-zinc-50 hover:text-gray-950 hover:border-[1px] hover:border-gray-950">
                                <NavLink to="/login">Đăng xuất</NavLink>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="flex justify-between items-center pb-8 cursor-pointer w-full overflow-hidden scroll-stable-center"
                onClick={handleToggle}
            >
                <div className="bg-gray-950 rounded-full basic-1/6 ml-4 min-w-[24px] aspect-square flex justify-center items-center">
                    <p className="  text-white font-bold text-sm">Y</p>
                </div>
                <div className="flex justify-between items-center basis-5/6 mr-6 ml-3">
                    <p className="basis-4/6 text-lg font-semibold">TRAN THI HAI YEN</p>
                    <span>
                        {isOpen ? (
                            <CaretDownFilled className="w-[22px] h-[22px]" />
                        ) : (
                            <CaretUpFilled className="w-[22px] h-[22px]" />
                        )}
                    </span>
                </div>
            </div>
        </section>
    );
}

export default SignOut;
