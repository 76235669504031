import React, { useState } from "react";
import ConfirmUploadFile from "../../../routing/pages/Book/UploadBook/Module/ConfirmUploadFile";
function SaveAndCancel(props) {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const handleOpenModalConfirm = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };
  return (
    <>
      <ConfirmUploadFile
        isOpenConfirmModal={isOpenConfirmModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
      />
      <div className="w-2/12 flex justify-between align-items-center">
        <button className="w-6/12 px-3 py-2 rounded-md text-red-600 text-l font-bold">
          Huỷ
        </button>
        <button
          className="w-6/12 px-3 py-2 rounded-md bg-zinc-400 text-white text-l font-bold flex justify-center align-middle"
          onClick={handleOpenModalConfirm}
        >
          <>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=" w-5 h-5 inline "
            >
              <path
                d="M27.7675 8.64626L23.3538 4.23251C23.1222 3.99957 22.8468 3.81488 22.5434 3.68914C22.24 3.56341 21.9147 3.49912 21.5863 3.50001H6C5.33696 3.50001 4.70107 3.7634 4.23223 4.23224C3.76339 4.70108 3.5 5.33697 3.5 6.00001V26C3.5 26.6631 3.76339 27.2989 4.23223 27.7678C4.70107 28.2366 5.33696 28.5 6 28.5H26C26.663 28.5 27.2989 28.2366 27.7678 27.7678C28.2366 27.2989 28.5 26.6631 28.5 26V10.4138C28.5009 10.0853 28.4366 9.76001 28.3109 9.45661C28.1851 9.15321 28.0004 8.87777 27.7675 8.64626ZM20.5 25.5H11.5V20H20.5V25.5ZM25.5 25.5H23.5V19.5C23.5 18.837 23.2366 18.2011 22.7678 17.7322C22.2989 17.2634 21.663 17 21 17H11C10.337 17 9.70107 17.2634 9.23223 17.7322C8.76339 18.2011 8.5 18.837 8.5 19.5V25.5H6.5V6.50001H21.375L25.5 10.625V25.5ZM20.5 10.5C20.5 10.8978 20.342 11.2794 20.0607 11.5607C19.7794 11.842 19.3978 12 19 12H12C11.6022 12 11.2206 11.842 10.9393 11.5607C10.658 11.2794 10.5 10.8978 10.5 10.5C10.5 10.1022 10.658 9.72065 10.9393 9.43935C11.2206 9.15804 11.6022 9.00001 12 9.00001H19C19.3978 9.00001 19.7794 9.15804 20.0607 9.43935C20.342 9.72065 20.5 10.1022 20.5 10.5Z"
                fill="#fff"
              />
            </svg>
          </>
          <span className="ml-1">Lưu</span>
        </button>
      </div>
    </>
  );
}

export default SaveAndCancel;
