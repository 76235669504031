import { get_file_upload_name } from "../constants/get_file_upload_name_constant";

const initialState = {
  name: "",
};

export default function get_file_upload_name_reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case get_file_upload_name:
      state.name = action.name;
      console.log(state);
      return { ...state };

    default:
      return { ...state };
  }
}
