import React from 'react';
import TheSidebar from './dashboard/sidebar/TheSidebar';

function IndexLayout({ children }) {
    return (
        <div className="flex justify-between overflow-x-hidden">
            <TheSidebar />
            <main className="ml-[25vw] w-3/4 px-[4vw] pt-[6vh]">{children}</main>
        </div>
    );
}

export default IndexLayout;
