import React from "react";
import { UploadSimpleFilled } from "../../../../../assets/icons";

function ButtonUploadFileCSV(props) {
  return (
    <div className="inline">
      <button className="flex justify-center items-center background-303030 text-white rounded-md">
        <UploadSimpleFilled className="h-6 w-6 inline ml-2" />
        <input
          type="file"
          className="w-[0.1px] h-[0.1px] overflow-hidden -z-10"
          id="upload_file_csv"
        />
        <label
          for="upload_file_csv"
          className="cursor-pointer w-full h-full py-2 pr-4 "
        >
          Tải danh sách từ file CSV
        </label>
      </button>
    </div>
  );
}

export default ButtonUploadFileCSV;
