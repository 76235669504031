import { Firebase } from './modules/common/database_common';
import { BrowserRouter } from 'react-router-dom';

import MainRoutes from './routing/indexRoutes';

export const database = new Firebase({
    apiKey: process.env.REACT_APP_API_KEY,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

function App() {
    database.init();

    return (
        <BrowserRouter>
            <div className="App">
                <MainRoutes />
            </div>
        </BrowserRouter>
    );
}

export default App;
