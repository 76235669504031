import React from 'react';
import logo from '../../../assets/images/logoNeuReader1.png';
import './TheSidebar.css';
import DropdownItem from './DropdownItem';
import { DataSideBar } from './DataSideBar';
import SignOut from './SignOut';

function TheSidebar(props) {
    return (
        <aside className="fixed top-0 left-0 right-0 bottom-0 bg-zinc-100 w-[25vw] transition border border-gray-200">
            <div className="flex flex-col justify-between h-[100vh] w-full">
                <div className="">
                    <figure className="h-[10vh] my-4">
                        <img src={logo} alt="" className="h-full object-cover m-auto mt-2" />
                    </figure>
                    <nav className="h-[75vh] toggle-scroll scroll-stable-center">
                        {DataSideBar.map((item, index) => {
                            return <DropdownItem key={index} dataSidebar={item} />;
                        })}
                    </nav>
                </div>
                <SignOut />
            </div>
        </aside>
    );
}

export default TheSidebar;
