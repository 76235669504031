import React from "react";
import SaveAndCancel from "../../../../../modules/common/components/SaveAndCancel";
import TableInfoAccount from "./TableInfoAccount";
import TableEditAccount from "./TableEditAccount";

import ButtonUploadFileCSV from "./ButtonUploadFileCSV";

function AddAccount(props) {
  return (
    <div className="w-11/12 mx-auto mt-[5vh]">
      <div className="flex justify-between items-center mb-[2vh]">
        <h1 className="text-[2vw] font-bold">Nhập tài khoản</h1>
        <SaveAndCancel />
      </div>
      <ButtonUploadFileCSV />

      <div className="flex justify-between rounded-md mt-[2vh]">
        <TableInfoAccount />
        <TableEditAccount />
      </div>
    </div>
  );
}

export default AddAccount;
