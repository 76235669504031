import { combineReducers } from "redux";
import add_book_reducer from "./add_book_reducer";
import getFileUploadNameReducer from "./get_file_upload_name_reducer";

const rootReducer = combineReducers({
  addBookReducer: add_book_reducer,
  getFileUploadNameReducer,
});

export default rootReducer;
