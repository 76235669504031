import React from 'react';
import './uploadbook.css';
import plus from '../../../../../assets/icons/filled/Plus.svg';

function TableEditBook(props) {
    return (
        <div className="w-4/12 ml-2">
            <table className="border-collapse border border-gray-400 rounded-2xl w-full ">
                <thead className="background-f2f2f2 ">
                    <tr>
                        <th className="border border-gray-200 pr-3 pl-2 text-start text-l font-bold whitespace-nowrap py-[3vh]">
                            Bìa sách
                        </th>
                        <th className="border border-gray-200 pr-3 pl-2 text-start text-l font-bold whitespace-nowrap">
                            File sách
                        </th>
                        <th className="border border-gray-200 pr-3 pl-2 text-start text-l font-bold whitespace-nowrap">
                            Chỉnh sửa
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                    <tr>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center"></td>
                        <td className="td border border-gray-200 text-center px-auto">
                            <img src={plus} alt="" className="block w-5 h-5 mx-auto" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TableEditBook;
