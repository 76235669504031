import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { add_book_action } from "../../../../../store/actions/add_book_action";
import "../../../../../index.css";
import { get_file_upload_name_action } from "../../../../../store/actions/get_file_upload_name_action";
function ModalUploadBookAndUser(props) {
  const fileInputRef = useRef(null);
  const handleFileChange = () => {
    const file = fileInputRef.current.files[0];
    props.dispatch(get_file_upload_name_action(file.name));
  };

  const [data, setData] = useState([]);
  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = new XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parseData = XLSX.utils.sheet_to_json(sheet);
      setData(parseData);
      console.log(parseData);
    };
  };

  return (
    <div
      className={`w-screen h-screen popup_signout fixed ${props.isOpenModal ? "activeSignOut" : "hiddenSignOut"} `}
    >
      <div className="bg-white border-[1px] w-[34vw] h-[30vh] rounded-md mx-auto mt-[36vh] p-[2vh]">
        <div>
          <div className="">
            <h3 className="text-[2.5vh] font-bold">
              Tải {props.name} từ file {props.nameFile}
            </h3>
            <p className="text-[2vh] py-[0.5vh]">
              1. Tải mẫu Excel&nbsp;
              <a
                href="https://docs.google.com/spreadsheets/d/1fsR2ApkhrTwhP1UQwR1LuIuy8Q0BkQhBBOhgkfR3r1A/edit?gid=0#gid=0"
                target="_blank"
                rel="noreferrer"
                className="font-bold underline"
              >
                tại đây
              </a>
            </p>
            <p className="text-[2vh] py-[0.5vh]">
              2. Điền tất cả các trường trong mẫu
            </p>
            <p className="text-[2vh] py-[0.5vh]">
              3. Tải file CSV đã điền lên hệ thống, sau đó tải bìa sách và file
              sách.
            </p>
          </div>
          <div className="flex items-center justify-between py-[2vh] float-end">
            <div className="">
              <button
                onClick={props.handleOpenModal}
                className="text-red-600 font-bold text-[2vh] py-2 px-[1.5vw]"
              >
                Huỷ
              </button>
              <div className="inline cursor-pointer">
                <input
                  type="file"
                  className="p-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute -z-10"
                  accept=".xlsx"
                  id="upload_excel_file"
                  ref={fileInputRef}
                  onChange={(handleFileChange, handleFileUpload)}
                />
                <label
                  htmlFor="upload_excel_file"
                  className="background-303030 text-white font-bold text-[2vh] py-2 px-[1.5vw] rounded-md inline cursor-pointer"
                  onClick={() => {
                    props.dispatch(add_book_action(data));
                    props.handleOpenModal();
                  }}
                >
                  Tại đây
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null)(ModalUploadBookAndUser);
